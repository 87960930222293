<template>
  <div class="layout">
    <!--    v-if="!meta.hideNavBar"-->
    <div class="space-nav-bar" v-if="!meta.hideNavBar">
      <van-nav-bar :title="meta.title" left-arrow fixed ref="refNavBar" @click-left="onClickLeft" />
    </div>
    <keep-alive><router-view v-if="$route.meta.keepAlive" /></keep-alive>
    <router-view class="avue-view" v-if="!$route.meta.keepAlive" />
<!-- 
    <div class="space-tabbar" v-if="meta.tabbar">
      <van-tabbar v-model="active" @change="onTabbarChange">
        <van-tabbar-item :icon="item.icon" :key="item.path" v-for="item in tabbarList">{{ item.text }}</van-tabbar-item>
      </van-tabbar>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getStore, setStore } from '@/utils/store'
import { getFirstEnterPath, setFirstEnterPath } from '@/utils'

export default {
  components: {},
  name: 'layout',
  data() {
    return {
      tabbarList: [
        {
          icon: 'home-o',
          path: '/home',
          text: '首页'
        },
        {
          icon: 'friends-o',
          path: '/user',
          text: '用户'
        }
      ],
      active: 0
    }
  },
  computed: {
    meta() {
      return this.$route.meta || {}
    }
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.setActive()
      }
    }
  },
  methods: {
    onTabbarChange(val) {
      let path = this.tabbarList[val].path
      if (path === this.$route.path) return
      this.$router.push(path)
    },
    onClickLeft() {
      if (this.$route.path === getFirstEnterPath()) {
        this.$router.push('/home')
        setFirstEnterPath('/home')
      } else {
        this.$router.back()
      }
    },
    setActive() {
      let path = this.$route.path
      let index = this.tabbarList.findIndex(item => item.path === path)
      this.active = index
    }
  },

  mounted() {},
  created() {}
}
</script>

<style scoped lang="scss">
.layout {
  .space-nav-bar {
    height: 92px;
  }

  .space-tabbar {
    height: 100px;
  }
}
</style>
