import Layout from '@/layout/index.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: {
          title: '首页',
          tabbar: true,
          hideNavBar: true
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/home')
      },
      {
        path: '/user',
        name: 'User',
        meta: {
          title: '用户',
          tabbar: true,
          hideNavBar: true
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/user')
      },
      {
        path: '/setting',
        name: 'Setting',
        meta: {
          keepAlive: true,
          title: '设置'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/setting')
      },
      {
        path: '/address',
        name: 'Address',
        meta: {
          title: '地址'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/address')
      },
      {
        path: '/recVideo',
        name: 'RecVideo',
        meta: {
          title: '视频录制',
          tabbar: true,
          hideNavBar: true
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/recVideo')
      },
      {
        path: '/recSuccess',
        name: 'RecSuccess',
        meta: {
          title: '结果',
          tabbar: true,
          hideNavBar: true
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/recSuccess')
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '@/views/error/404'),
    meta: {
      title: '404',
      isHide: true
    }
  }
]

export default routes
