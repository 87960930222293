import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios'
import * as filters from '@/filters' // 全局filter
import '@/plugins'

Vue.config.productionTip = false
import '@/styles/index.scss'

// 加载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
